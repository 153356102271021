<template>

    <div>

    <div v-if="errors" class="alert alert-danger">
        <ul v-for="(v, k) in errors" :key="k">
            <li v-for="error in v" :key="error">{{ error }}</li>
        </ul>
    </div>

    <div v-if="success" class="alert alert-success" role="alert">
      Gelukt! Uw verzoek is verzonden.
    </div>

        <div v-if="error" class="alert alert-danger" role="alert">
          {{ this.error }}
        </div>

      <ValidationObserver v-slot="{ invalid }" ref="appointmentForm">
          <b-form @submit.prevent="submit()">
              <b-row v-if="!$route.params.type">
                <b-col cols="12" sm="10">
                   <ValidationProvider :name="$t('appointment_type')" rules="required" v-slot="{ errors }">
                        <b-form-group
                          id="fieldset-10"
                          label-align="left"
                          label-for="input-10">
                          <template v-slot:label>{{ $t('appointment_type')}} <span class="text-danger">*</span></template>

                          <b-form-select v-model="appointment.type" :options="appointment_types" :class="['form-control',  errors[0] ? 'is-invalid' : '']"></b-form-select>

                          <div class="float-left text-danger">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="10">
                   <ValidationProvider :name="$t('object_id')" rules="required" v-slot="{ errors }">
                        <b-form-group
                          id="fieldset-10"
                          label-align="left"
                          label-for="input-10">
                          <template v-slot:label>{{ $t('object_id')}} <span class="text-danger">*</span></template>
                          <b-form-input id="input-10" v-model="appointment.object_id" :class="['form-control',  errors[0] ? 'is-invalid' : '']"></b-form-input>
                          <div class="float-left text-danger">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
              </b-row>

            <b-row v-if="$route.params.uuid == '0c34e428-a80a-47db-b747-5f63149c77ee'">
              <b-col cols="12" sm="10">
                <ValidationProvider :name="$t('object_type')" rules="required" v-slot="{ errors }">
                  <b-form-group
                      id="fieldset-9"
                      label-align="left"
                      label-for="input-9">
                    <template v-slot:label>{{ $t('object_type')}} <span class="text-danger">*</span></template>
                    <b-form-select
                        v-model="appointment.object_type_id"
                        :options="object_types"
                        value-field="id"
                        text-field="name"
                        :class="['form-control',  errors[0] ? 'is-invalid' : '']"
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled>{{ $t('choose') }}</b-form-select-option>
                      </template>
                    </b-form-select>

                    <div class="float-left text-danger">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>

              <b-row>
                <b-col cols="12" sm="10">
                   <ValidationProvider :name="$t('name')" rules="required" v-slot="{ errors }">
                        <b-form-group
                          id="fieldset-10"
                          label-align="left"
                          label-for="input-10">
                          <template v-slot:label>{{ $t('name')}} <span class="text-danger">*</span></template>
                          <b-form-input id="input-10" v-model="appointment.name" :class="['form-control',  errors[0] ? 'is-invalid' : '']"></b-form-input>
                          <div class="float-left text-danger">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
              </b-row>

               <b-row>
                 <b-col cols="12" sm="10">

                   <ValidationProvider :name="$t('appointment_at_date')" rules="required" v-slot="{ errors }">
                     <b-form-group
                         id="fieldset-10"
                         label-align="left"
                         label-for="input-10">
                         <template v-slot:label>{{ $t('appointment_at_date') }} <span class="text-danger">*</span></template>

                       <v-date-picker
                           is-expanded
                           :available-dates=availableDates
                           v-model="appointment.appointment_at_date"
                           @update:to-page="changeMonth"
                           @dayclick="changeDay"
                       />

                       <div class="float-left text-danger">
                         <span>{{ errors[0] }}</span>
                       </div>
                     </b-form-group>
                   </ValidationProvider>

                 </b-col>
               </b-row>

            <b-row>
              <b-col cols="12" sm="10">

                <ValidationProvider :name="$t('appointment_at_time')" rules="required" v-slot="{ errors }">
                  <b-form-group
                      id="fieldset-10"
                      label-align="left"
                      label-for="input-10">
                    <template v-slot:label>{{ $t('appointment_at_time')}} <span class="text-danger">*</span></template>

                    <b-form-select v-if="hours.length" v-model="appointment.appointment_at_time" :options="hours"></b-form-select>

                    <span v-else style="color: red;">Kies eerst een datum</span>

                    <div class="float-left text-danger">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

              </b-col>
            </b-row>

              <b-row>
                <b-col cols="12" sm="10">
                   <ValidationProvider :name="$t('email')" rules="required" v-slot="{ errors }">
                        <b-form-group
                          id="fieldset-10"
                          label-align="left"
                          label-for="input-10">
                          <template v-slot:label>{{ $t('email')}} <span class="text-danger">*</span></template>
                          <b-form-input id="input-10" v-model="appointment.email" :class="['form-control',  errors[0] ? 'is-invalid' : '']"></b-form-input>
                          <div class="float-left text-danger">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
              </b-row>

                <b-row>
                    <b-col cols="12" sm="10">
                      <b-form-group
                      id="fieldset-12"
                      :label="$t('note')"
                      label-align="left"
                      label-for="input-12"
                        >
                      <b-form-textarea
                      id="textarea"
                      v-model="appointment.note"
                      rows="3"
                      max-rows="6"
                      ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                </b-row>

            <b-button type="submit" variant="primary" :disabled="invalid" class="float-left">{{ $t('submit_appointment') }} </b-button>
          </b-form>
      </ValidationObserver>

  </div>

</template>

<script>

import axios from "axios";

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate/dist/vee-validate.full.esm';

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
// setting the error message dynamically
import en from 'vee-validate/dist/locale/en.json';
import nl from 'vee-validate/dist/locale/nl.json';
import i18n from "../i18n";

localize({
  en,
  nl
});
localize(i18n.locale);

export default {
  name: 'App',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
        dates: [],
        hours: [],
        availableDates: [],
        user: null,
        appointment: {
            location_id: null,
            appointment_at_date: null,
            appointment_at_time: null,
            type: null,
        },
        errors: null,
        error: null,
        success: null,
        appointment_types: [
         { value: null, text: this.$t('choose') },
         { value: 1, text: this.$t('pickup') },
         { value: 2, text: this.$t('return') }
       ],
      object_types: {}
    }
  },

//

  computed: {



  },

  mounted() {

      // get settings
       axios
        .get(process.env.VUE_APP_API_URL + 'iframe/' + this.$route.params.uuid)
        .then(response => {
         this.user = response.data.result
       })
        .catch(error => {
            if(error) { // not found
                console.log(error)
            }
       })

      // get available days
      //this.getAvailableDays(this.$moment().format('Y'), this.$moment().format('M'))

      // set default appointment type
       if(this.$route.params.type) {
        this.appointment.type = this.$route.params.type
       }

    // get object types
    if(this.$route.params.uuid === '0c34e428-a80a-47db-b747-5f63149c77ee') { // We need this only for client "Boekel"
      axios.get(process.env.VUE_APP_API_URL + 'iframe/object_types/' + this.$route.params.uuid)
          .then(response => {
            this.object_types = response.data.result
          })
          .catch(error => {
            if(error.response.status == 404) { // not found
              console.log('Not found')
            }
          })
    }
  },
  methods: {

    getAvailableDays(year, month) {

       axios
          .get(process.env.VUE_APP_API_URL + 'hours/' + this.$route.params.uuid + '/' + year + '/' + month + '/' + this.$route.params.type, {
            headers: {
              'X-localization': 'nl'
            }
          })
          .then(response => {
            this.dates = response.data.data

            // there are any availabilities for the selected month
            if(Object.keys(this.dates).length > 0) {

              for (var item in response.data.data) {
                this.availableDates.push(
                    {
                      start: new Date(item),
                      end: new Date(item)
                    }
                )
              }

              // set a date in case of any results
              if(!this.appointment.appointment_at_date) {

                // set first possible date
                this.appointment.appointment_at_date = Object.keys(this.dates)[0]

                // get available hours
                // we make use of existing ChangeDay function (API) so we need to push {id} here
                this.changeDay({
                  id: this.appointment.appointment_at_date
                })

              }

            } else {

              // no availability: @todo: hacked this a bit. You cant have a empty array to have no results in a month.. it gets all available with empty array
              this.availableDates.push(
                  {
                    start: new Date(1980, 1, 1),
                    end: new Date(1980, 1, 2)
                  }
              )

            }

          })
          .catch(error => {

            if(error.response.status == 422) {
              this.errors = error.response.data.errors;
            } else {
              // error.response.data.message write this in this.errors array
              this.error = error.response.data.message
            }
          })

    },

    // this function will always get triggered on Load, so we dont have to add it to Mounted()
    changeMonth(date) {
      this.getAvailableDays(date.year, date.month)
    },

    changeDay(day) {

      // get new hours only, if not disabled
      if(!day.isDisabled) {

        this.hours = this.dates[day.id]

        // if time already set? Verify if chosen time is still available on new picked date
        // no? Pick first available time
        if(this.appointment.appointment_at_time) {
          if(!this.hours.includes(this.appointment.appointment_at_time)) {
            this.appointment.appointment_at_time = this.hours[0]
          }

        }

      }

    },


    async submit() {

        // check appointment form here
        const valid = await this.$refs.appointmentForm.validate();
        if (!valid ) {
          return;
        }

        this.appointment.uuid = this.$route.params.uuid
        // create a parseable date
        this.appointment.appointment_at = this.$moment(this.appointment.appointment_at_date).format('YYYY-MM-DD') + ' ' + this.appointment.appointment_at_time
        this.appointment.emails = {
            customer: 1,
            owner: 1
        }

        // reset vars
        this.errors = ''
        this.success = ''
        this.error = ''

        axios
            .post(process.env.VUE_APP_API_URL + 'iframe/appointments/' + this.$route.params.uuid, this.appointment, {
              headers: {
                'X-localization': 'nl'
              }}
            )
            .then(response => {
                //console.log(response)
                this.appointment = {}
                this.success = true
                this.$nextTick(() => {
                  this.$refs.appointmentForm.reset();
                });

            })
            .catch(error => {
              if (error.response.status == 422) {
                this.errors = error.response.data.errors;
              } else {
                // error.response.data.message write this in this.errors array
                this.error = error.response.data.message;
              }

            })

        if (window.top !== window.self) {
          // We are inside an iframe, send a message to the parent to scroll to the iframe
          window.parent.postMessage('scrollToTop', '*');
        } else {
          // Not inside an iframe, scroll normally
          window.scrollTo(0, 0);
        }

    }
  }
}
</script>

